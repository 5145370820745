body {
	/* padding-top: 15px; */
	/* padding-left: 10px; */
	/* padding-bottom: 15px; */
	/* padding-right: 10px; */
}

.content {
	/* display: flex; */
	/* justify-content: center; */
	/* padding-bottom: 15px; */
	padding-left: 10px;
	padding-right: 10px;
	height: 100%;
	flex: auto;
	overflow: auto;
}

.content-table {
	height: 100%;
}

.header {
	/* background-color: #00843d; */
	display: flex;
	height: 100px;
	width: 100%;
	justify-content: space-between;
}

.header-title {
	padding: 10px;
	align-self: center;
	text-align: center;
	flex: 1;
	color: #00843d;
	/* color: white; */
}

.header-logo {
	flex: 1;
}

.ag-watermark {
	display: none !important;
}
