html,
body,
#root {
	height: 100%;
	width: 100%;
	margin: 0;
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
	display: flex;
	flex-direction: column;
}

html {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	overflow: auto;
}
